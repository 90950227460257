


  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label,
.form-control select {
  display: block;
}

.form-control input,
.form-control select {
  padding: 0.5rem;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}
.css-1s2u09g-control {
  border-radius: 20px;
  border: 1px solid #ccc;
  max-width: 100%;
  color: white !important;
}
.css-qc6sy-singleValue {
  color: #6C757D!important;
}

.form-control input:focus,
.form-control select:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
  color: white !important;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #E0984A;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;

}

.form-actions button {
  margin-left: 1rem;
}

.invalid input, .invalid select {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus, .invalid select:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}



