/* make the customizations */
$theme-colors: (
        "primary":#2E31D2,
        "secundary":#D3D3D3,
        "light": #D3D3D3
);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* import bootstrap to set changes */
@import "../~bootstrap/scss/bootstrap";

.btn-secundary {
    color: #ffffff;
    
}
.btn-secundary:hover {
    color: #ffffff
}

.btn {
    border-radius: 20px;
}


.background-gradient2 {
    background-image: linear-gradient(to top, #D3D3D3, white);
   
}

.strong {
    font-weight: bold;
    font-size: 15px;
}

.bg-danger {
    background-color: #D3D3D3 !important;
}

.bg-warning {
    background-color:  #D3D3D3!important;
}

.bg-success {
    background-color:  #D3D3D3 !important;
}



.captchaform {
    display: inline-block;
    margin-top: 20px;
  
    
}

.captcha {
  margin: 0;
  padding: 0;
}


.button-form{
    margin-bottom: 30px;
}



.placeholdertext{
    color: #a9a9a9;
    
}

.display-6 {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
}

.display-5 {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
}

.lead {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;}

.lead2 {
    font-family: 'Roboto', sans-serif;;   
    font-size: 20px;
   }

h1 {
    font-family: 'Roboto', sans-serif;
}

.trashbin {
    color: #D3D3D3;
    font-size: 20px;
    margin-left: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;

}

.addButton {  
    color: white;
    font-size: 30px;
    align-items: center;


}

.padding-left-class {
    padding-left: 60px;
}

.bg-light {
    background-color: #D3D3D3 !important;
}

.center-items {
    display: inline-flexbox;
    justify-content: center;
    align-items: center;
}

.textDecoNone {
    list-style: none;
}
#section-hero {
    background-image: url("./images/RIL Assets-10.png");
            background-size: cover;
            background-position: center;
        }

    .fullscreen {
    height: 100vh;

  }

  .text-font {
    font-family: 'Roboto', sans-serif;

  }

  #aboutus {
    background-image: url("./images/RIL Assets-11.png");
    background-size: cover;
            background-position: center;
            min-height: 50vh;
  }
  #classes {
    background-image: url("./images/RIL Assets-12.png");
    background-size: cover;
            background-position: center;
            min-height: 40vh;  
  }

  .pointer {
    cursor: pointer;
  }

 
  .inner {
    position: absolute;
  }

  .dropdown-menu.show {
    background-color: #699DEE;
  }

  .blue-circle {
    border-radius: 50%;
    background-color: #E7F3FE;
    width: 350px;
    height: 350px;
  }

  .light-blue{
    background-color: #E7F3FE;
  }
  
  .bg-blue {
    background-color:#699DEE;
    border: #699DEE;
    font-family: Roboto;
  }

  .borderblue {
    border: #699DEE !important;
    border-style: solid !important;
    border-width: 3px !important;
    cursor: pointer;

  }
  .borderblue:hover {
    border: #F1A34F!important;
    border-style: solid !important;
    border-width: 3px !important;

  }
  .borderorange {
    border: #F1A34F !important;
    border-style: solid !important;
    border-width: 3px !important;
    cursor: pointer;
  }

  #TranslationPage {
    background-image: url("./images/RIL Assets-13.png");
            background-size: cover;
            background-position: center;
    min-height: 100vh;

  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: #F1A34F!important;
  }
  
  #translations {
    background-color: #F1A34F;   
    background-size: cover;
            background-position: center;
            min-height: 50vh;          
  }

  .bg-orange {
    background-color: #F1A34F;
  }

  #prices {
    background-size: cover;
            background-position: center;
            min-height: 50vh; 
  }

  #contactus {
    background: #333333;

  }
  #footer {
    background: #1A1A1A;
  } 

  .bg-darkgrey {
    background-color: #1A1A1A;
  }
  .bg-grey {
    background: #333333;
  }

  .button-fit-content {
    width: fit-content;
    height: fit-content;
  }

   .hover:hover {
      color: #F1A34F !important;
      opacity: 1 !important;
   }

   .hover2:hover {
     background-color: #F1A34F !important;
     border-radius: 5px;

   }

   .float-right {
     float: right !important;
   }

  .opacity100{
    opacity: 1;
    animation: opacity   0.5s;
  }

  .opacity100Late{
  opacity: 1;
  animation: opacitylate 0.5s;
  }

  .opacity100Latest{
  opacity: 1;
  animation: opacitylatest 0.5s;
  }

@keyframes opacity {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacitylate {
  0% {
      opacity: 0;
  }
  25% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


@keyframes opacitylatest {
  0% {
      opacity: 0;
  }
  25% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  75% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


@media (max-width: 768px) {
  .inputText {
    width: 100% !important;
    
  }
  .inputButton {
    width: 100% !important;
  }
}

.align {
  display: flex;
  h4 {
    align-self: center;
  }
}

.width15 {
  width: 15%;
}

.text-heading-prices {
  color: black;
  display: flex;
  align-items: flex-end;
}







